/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { RecoilRoot } from 'recoil';
import './src/templates/style.css'

export const wrapRootElement = ({ element, props }) => (
    <RecoilRoot {...props}>{element}</RecoilRoot>
)